<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        استعراض
                        الحجاج
                    </h5>
                </div>
                <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-2 g">
                        <span>البحث</span>
                        <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; gett();">
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">الحملة</span>
                          <select class="form-control" v-model="hamla" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option value="1">البشائر</option>
                            <option value="2">الميسر</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">الجنس</span>
                          <select class="form-control" v-model="gender" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option value="ذكر">ذكر</option>
                            <option value="انثى">انثى</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">منى/المسير</span>
                          <select class="form-control" v-model="camp_id" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <template v-for="camp in camps">
                                <option :key="camp._id" v-if="camp.type == '1'" :value="camp._id">{{ camp.title }}</option>
                            </template>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">سكن عرفات</span>
                          <select class="form-control" v-model="camp2_id" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <template v-for="camp in camps">
                                <option :key="camp._id" v-if="camp.type == '2'" :value="camp._id">{{ camp.title }}</option>
                            </template>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">الحالة</span>
                          <select class="form-control" v-model="status" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option v-for="xstatus in statuses" :value="xstatus" :key="xstatus">{{ xstatus }}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">الحافلة</span>
                          <select class="form-control" v-model="bus_id" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option v-for="bus in busses" :value="bus._id" :key="bus._id">{{ bus.title }}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 g">
                        <div class="form-group">
                          <span for="">نقطة الانطلاق</span>
                          <select class="form-control" v-model="start_location" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option v-for="c in cities" :key="c" :value="c">
                                {{ c }}
                            </option>
                          </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-2 g">
                        <br>
                        <b-dropdown dropleft style="width: 100%;"
                            id="dropdown-1xx"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="خيارات"
                            variant="secondary"
                            size="sm"
                        >
                            <b-dropdown-item @click="print()">
                                <i class="fa fa-print"></i>
                                طباعة الصفحة
                            </b-dropdown-item>
                            <b-dropdown-item @click="excel()">
                                <i class="fa fa-download"></i>
                                تصدير الى Excel
                            </b-dropdown-item>
                            <b-dropdown-item @click="cards()">
                                <i class="fa fa-id-card"></i>
                                طباعة
                                البطاقات
                            </b-dropdown-item>
                            <b-dropdown-item @click="cards2()">
                                <i class="fa fa-id-card-o"></i>
                                طباعة
                                الملصقات
                            </b-dropdown-item>
                            <b-dropdown-item @click="cards5()">
                                <i class="fa fa-id-card-o"></i>
                                طباعة
                                الملصقات (عرفات)
                            </b-dropdown-item>
                            <b-dropdown-item @click="cards3()">
                                <i class="fa fa-id-card-o"></i>
                                طباعة
                                الاسوار
                            </b-dropdown-item>
                            <b-dropdown-item @click="all='yes'; gett()">
                                <i class="fa fa-list"></i>
                                عرض الكل
                            </b-dropdown-item>
                        </b-dropdown>   
                    </div>
                </div>
                <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
                    <div class="col-12 table-responsive" id='tt'>
                        <table class="table table-hover table-bordered table-sm" id="table">
                            <tr>
                                <th class="dnone">م</th>
                                <th>
                                    الاسم
                                </th>
                                <th>
                                    الهوية
                                </th>
                                <th>
                                    الجوال
                                </th>
                                <th>
                                    الجنس
                                </th>
                                <th>
                                    الحالة
                                </th>
                                <th>
                                    رقم الحجز
                                </th>
                                <th>
                                    الباقة
                                </th>
                                <th>
                                    الجنسية
                                </th>
                                <th>
                                    الحافلة
                                </th>
                                <th>
                                    السكن
                                </th>
                                <th>
                                    نقطة الانطلاق
                                </th>
                                <th class="hideme" v-if="!printing">
                                    خيارات
                                </th>
                            </tr>
                            <tbody>
                                <tr v-for="(user, i) in users" :key="user._id">
                                    <td class="dnone">{{ i + 1 }}</td>
                                    <td>
                                        {{ user.name }}
                                    </td>
                                    <td>
                                        {{ user.number }}
                                    </td>
                                    <td>
                                        {{ user.phone }}
                                    </td>
                                    <td>
                                        {{ user.gender }}
                                    </td>
                                    <td>
                                        <span v-if="user.status">
                                            <span :class="user.status.includes('ملغي') ? 'text-danger' : (user.status.includes('نتظار') ? 'text-warning' : 'text-success')">
                                                {{ user.status }}
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        {{ user.order_id }}
                                    </td>
                                    <td>
                                        {{ user.slide }}
                                    </td>
                                    <td>
                                        {{ user.national }}
                                    </td>
                                    <td>
                                        {{ user.bus_obj.title }}
                                    </td>
                                    <td>
                                        {{ user.camp_obj.title }}
                                    </td>
                                    <td>
                                        {{ user.start_location }}
                                    </td>
                                    <td class="hideme" v-if="!printing">
                                     <b-dropdown dropleft style="width: 100%;"
                                            id="dropdown-1x"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            text="خيارات"
                                            variant="secondary"
                                            size="sm"
                                        >
                                            <b-dropdown-item @click="current = user" v-b-modal.edit>
                                                <i class="fa fa-edit"></i>
                                                تعديل الحاج
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="current = user" v-b-modal.send>
                                                <i class="fa fa-whatsapp"></i>
                                                ارسال
                                                رسالة
                                            </b-dropdown-item>
                                            <b-dropdown-item style="font-size: 12px" @click="deleteUser(user._id)">
                                                <i class="fa fa-trash"></i>
                                                حذف الحاج
                                            </b-dropdown-item>
                                        </b-dropdown>   
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-danger" @click="page--; gett()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                        <button class="btn btn-primary" @click="page++; gett()" v-if="users.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="send" title="ارسال رسالة" hide-footer>
        <h5>
            ارسال رسالة الى {{ current.name }}
        </h5>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="3"></textarea>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="sendNow()">
                <i class="fa fa-whatsapp"></i>
                ارسال بالواتس الذكي
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل" hide-footer>
        <template v-if="current._id">
            <div class="form-group">
                <label for="">الاسم</label>
                <input type="text"
                    class="form-control" v-model="current.name">
            </div>
            <div class="form-group">
                <label for="">رقم الهوية</label>
                <input type="text"
                    class="form-control" v-model="current.number">
            </div>
            <div class="form-group">
                <label for="">كلمة المرور</label>
                <input type="text"
                    class="form-control" v-model="current.password">
            </div>
            <div class="form-group">
                <label for="">الجوال</label>
                <input type="text"
                    class="form-control" v-model="current.phone">
            </div>
            <div class="form-group">
                <label for="">الحالة</label>
                <select class="form-control" v-model="current.status">
                    <option v-for="xstatus in statuses" :value="xstatus" :key="xstatus">{{ xstatus }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">رقم الحجز</label>
                <input type="text"
                    class="form-control" v-model="current.order_id">
            </div>
            <div class="form-group">
                <label for="">تاريخ الحجز</label>
                <input type="text"
                    class="form-control" v-model="current.order_date">
            </div>
            <div class="form-group">
                <label for="">تاريخ الميلاد</label>
                <input type="text"
                    class="form-control" v-model="current.birthdate">
            </div>
            <div class="form-group">
                <label for="">الجنس</label>
                <select v-model="current.gender" class="form-control">
                    <option value="انثى">انثى</option>
                    <option value="ذكر">ذكر</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">الجنسية</label>
                <input type="text"
                    class="form-control" v-model="current.national">
            </div>
            <div class="form-group">
                <label for="">المخيم</label>
                <input type="text"
                    class="form-control" v-model="current.camp">
            </div>
            <div class="form-group">
                <label for="">الخطة</label>
                <input type="text"
                    class="form-control" v-model="current.package">
            </div>
            <div class="form-group">
                <label for="">الشريحة</label>
                <input type="text"
                    class="form-control" v-model="current.slide">
            </div>
            <div class="form-group">
                <label for="">المدينة</label>
                <input type="text"
                    class="form-control" v-model="current.city">
            </div>
            <div class="form-group">
                <label for="">النقل</label>
                <input type="text"
                    class="form-control" v-model="current.transport">
            </div>
            <div class="form-group" v-if="manual_location">
                <label for="">نقطة الانطلاق <i class="fa fa-edit" @click="manual_location = !manual_location"></i></label>
                <input type="text"
                    class="form-control" v-model="current.start_location">
            </div>
            <div class="form-group"  v-if="!manual_location">
              <label for="">نقطة الانطلاق <i class="fa fa-edit" @click="manual_location = !manual_location"></i></label>
              <select class="form-control" v-model="current.start_location">
                <option v-for="c in cities" :key="c" :value="c">
                    {{ c }}
                </option>
              </select>
            </div>
            <div class="form-group">
                <label for="">ملاحظات</label>
                <input type="text"
                    class="form-control" v-model="current.notes">
            </div>
            <div class="form-group">
              <label for="">الحافلة</label>
              <select class="form-control" v-model="current.bus_id">
                <template v-for="bus in busses">
                    <option :key="bus._id" :value="bus._id" v-if="bus.hamla == current.hamla && bus.start_location == current.start_location || true">
                        {{ bus.title }}
                    </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label for="">صالة منى</label>
              <select class="form-control" v-model="current.camp_id">
                <template v-for="camp in camps">
                    <option v-if="camp.type == '1' && camp.hamla == current.hamla" :key="camp._id" :value="camp._id">
                        {{ camp.title }}
                    </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label for="">صالة عرفات</label>
              <select class="form-control" v-model="current.camp2_id">
                <template v-for="camp in camps">
                    <option v-if="camp.type == '2' && camp.hamla == current.hamla" :key="camp._id" :value="camp._id">
                        {{ camp.title }}
                    </option>
                </template>
              </select>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    حفظ التعديلات
                </button>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem("user")),
      users: [],
      loading: false,
      printing: false,
      current: {},
      manual_location: false,
      q: "",
      hamla: "all",
      page: 0,
      status: "all",
      statuses: [],
      bus_id: "all",
      status_for_delete: "",
      start_location: "all",
      camps: [],
      busses: [],
      camps_obj: {},
      busses_obj: {},
      cities: [],
      gender: "all",
      camp_id: "all",
      camp2_id: "all",
      message: "",
      all: false
    }
  },
  created(){
    if(!checkPer('users')){
        this.$router.push('/pers')
        return;
    }
    var g = this;
    g.gett()
    $.post(api + '/admin/busses/list', {
        jwt: g.user.jwt
    }).then(function(r){
        g.busses = r.response;
        g.busses.forEach(function(a){
            g.busses_obj[a._id] = a;
        })
    })
    $.post(api + '/admin/camps/list', {
        jwt: g.user.jwt
    }).then(function(r){
        g.camps = r.response;
        g.camps.forEach(function(a){
            g.camps_obj[a._id] = a;
        })
    })
  },
  methods: {
    gett(){
      var g = this;
      $.post(api + '/admin/users/list', {
        all: g.all,
        jwt: this.user.jwt,
        q: this.q,
        hamla: this.hamla,
        page: this.page,
        status: this.status,
        start_location: this.start_location,
        bus_id: this.bus_id,
        gender: this.gender,
        camp_id: this.camp_id,
        camp2_id: this.camp2_id
      }).then(function(a){
        g.users = a.response;
        g.users.forEach(function(b){
            if(!g.statuses.includes(b.status)){
                g.statuses.push(b.status);
            }
            if(!g.cities.includes(b.start_location)){
                g.cities.push(b.start_location)
            }
        })
      })
    },
    sendNow(){
        var g = this;
        $.post(api + '/admin/users/send-single', {
            jwt: g.user.jwt,
            message: g.message,
            to: g.current.phone,
            number: g.current.number
        }).then(function(r){
            alert("تم الارسال بنجاح", 100)
        })
    },
    deleteUser(id){
        if(confirm("متأكد من حذف الحاج؟")){
            var g = this;
            $.post(api + '/admin/users/delete', {
                jwt: g.user.jwt,
                id: id
            }).then(function(a){
                g.gett();
                alert("تم الحذف بنجاح", 100);
            })
        }
    },
    save(){
        var g = this;
        $.post(api + '/admin/users/save', {
            jwt: g.user.jwt,
            id: g.current._id,
            user: g.current
        }).then(function(a){
            g.gett();
            alert("تم التعديل بنجاح", 100);
        })
    },
    print(id, title){
        var title = prompt("عنوان الكشف", "كشف الحجاج")
        var divToPrint = $("#tt");
        var newWin= window.open("");
        newWin.document.write(`
        <style>
        table, td, th {
            border: 1px solid #999;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl
        }
        .hideme{
            display:none
        }
        tr:nth-of-type(20n){
            page-break-after: always;
        }
        .x{
           position: relative;
           top: 100px
        }
        .banner{
            width: 100%;
            position: fixed;
            top: 0px
        }
        .footer{
            width: 100%;
            position: fixed;
            bottom: 0px
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        @media print and (orientation: portrait) {
            .banner2{
                display: none
            }
            .footer2{
                display: none
            }
        }
        @media print and (orientation: landscape) {
            .banner{
                display:none
            }
            .footer{
                display: none
            }
            .banner2{
                width: 100%;
                position: fixed;
                top: 0px
            }
            .footer2{
                width: 100%;
                position: fixed;
                bottom: 0px
            }
        }
        h5{
            position: absolute;
            top: -50px;
            right: 10px
        }
        </style>
        `)
        newWin.document.write(`
            <img class='banner' src='images/print-banner.png'>
            <img class='banner2' src='images/land-banner.png'>
            <div class='x'>
            <h5>${title}</h5>
            ${divToPrint.html()}</div>
            <img class='footer' src='images/print-footer.png'>
            <img class='footer2' src='images/land-footer.png'>
        `);
        newWin.document.close()
        newWin.print();
    },
    cards(){
        var g = this;
        var newWin= window.open("");
        newWin.document.write(`
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet">
        <style>
        *{
            font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
        }
        table, td, th {
            border: 1px solid #999;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl;
            height: 100%
        }
        .card{
            border: 2px dashed #ddd !important;
            height: 86mm;
        }
        .card .f{
            background: url(images/card.png?v=3);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .card .f2{
            background: url(images/card-back.jpg?v=2);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            padding: 0px;
            height: 100%
        }
        .f .text{
            position: relative;
            top: 90px
        }
        span{
            font-size: 14px;
            font-weight: bold;
        }
        @media print {
            @page {
                size: 54mm 86mm;
                margin: 0;
            }
        }
        </style>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css">
        `)
        var cards = "";
        g.users.forEach(function(a){
            cards = cards + 
            `<div class='card' style='width: 100%; height: 86mm; padding: 0px'>
                <div class='f'>
                    <div class='text text-center col-12'>
                        <span>${a.name}</span>
                        <br><br>
                        <img style='width: 50%' src='https://quickchart.io/chart?cht=qr&chld=H%7C1&chs=400x400&chl=${encodeURI(a.number)}'>
                        <br>
                        <br>
                        <span>الحافلة: ${g?.busses_obj[a?.bus_id]?.title ?? ""}</span>
                    </div>
                </div>
            </div>`
            cards = cards + 
            `<div class='card' style='width: 100%; height: 86mm; padding: 0px'>
                <div class='f f2'>
                    <div class='text text-center col-12'>
                    </div>
                </div>
            </div>`
        })
        newWin.document.write(`
            <div class='container-fluid'>
                <div class='row'>
                    ${cards}
                </div>
            </div>
        `);
        newWin.document.close()
        setTimeout(() => {
            newWin.print();
        }, 1000);
    },
    cards2(){
        var g = this;
        var newWin= window.open("");
        newWin.document.write(`
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet">
        <style>
        *{
            font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
        }
        table, td, th {
            border: 1px solid #999;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl;
            height: 100%
        }
        .card{
            border: 2px dashed #ddd !important;
            height: 150mm;
        }
        .card .f{
            background: url(images/card2.png?v=2);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .card .f2{
            background: url(images/card-back.jpg?v=2);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .f .text{
            position: relative;
            top: 150px
        }
        span{
            font-size: 18px
        }
        @media print {
            @page {
                size: 100mm 150mm;
                margin: 0;
            }
        }
        </style>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css">
        `)
        var cards = "";
        g.users.forEach(function(a){
            cards = cards + 
            `<div class='card' style='width: 100%; height: 150mm;'>
                <div class='f'>
                    <div class='text text-center col-12'>
                    <br><br><br><br>
                        <h5>${a.name}</h5>
                        <span>${a.number}</span><br><br><br>
                        <span>${g?.busses_obj[a?.bus_id]?.title ?? "--"}</span>
                        <br><br>
                        <div class='row' style='position: relative; top: -10px'>
                        
                            <div class='col-6 text-left' style='position: relative; right: 50px'>${g?.camps_obj[a?.camp_id]?.title ?? "--"}</div>
                            <div class='col-6 text-left' style='position: relative; right: 20px'>${a?.bed ?? "--"}</div>
                        
                        </div>
                        <img style='width: 70px' src='https://quickchart.io/chart?cht=qr&chld=H%7C1&chs=400x400&chl=${encodeURI(a.number)}'>
                        <br>
                        <br>
                    </div>
                </div>
            </div>`
        })
        newWin.document.write(`
            <div class='container-fluid'>
                <div class='row'>
                    ${cards}
                </div>
            </div>
        `);
        newWin.document.close()
        setTimeout(() => {
            newWin.print();
        }, 1000);
    },
    cards5(){
        var g = this;
        var newWin= window.open("");
        newWin.document.write(`
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet">
        <style>
        *{
            font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
        }
        table, td, th {
            border: 1px solid #999;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl;
            height: 100%
        }
        .card{
            border: 2px dashed #ddd !important;
            height: 150mm;
        }
        .card .f{
            background: url(images/card2.png?v=2);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .card .f2{
            background: url(images/card-back.jpg?v=2);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .f .text{
            position: relative;
            top: 150px
        }
        span{
            font-size: 18px
        }
        @media print {
            @page {
                size: 100mm 150mm;
                margin: 0;
            }
        }
        </style>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css">
        `)
        var cards = "";
        g.users.forEach(function(a){
            cards = cards + 
            `<div class='card' style='width: 100%; height: 150mm;'>
                <div class='f'>
                    <div class='text text-center col-12'>
                    <br><br><br><br>
                        <h5>${a.name}</h5>
                        <span>${a.number}</span><br><br><br>
                        <span>${g?.busses_obj[a?.bus_id]?.title ?? "--"}</span>
                        <br><br>
                        <div class='row' style='position: relative; top: -10px'>
                        
                            <div class='col-6 text-left' style='position: relative; right: 50px'>${g?.camps_obj[a?.camp2_id]?.title ?? "--"}</div>
                            <div class='col-6 text-left' style='position: relative; right: 20px'>${a?.bed2 ?? "--"}</div>
                        
                        </div>
                        <img style='width: 70px' src='https://quickchart.io/chart?cht=qr&chld=H%7C1&chs=400x400&chl=${encodeURI(a.number)}'>
                        <br>
                        <br>
                    </div>
                </div>
            </div>`
        })
        newWin.document.write(`
            <div class='container-fluid'>
                <div class='row'>
                    ${cards}
                </div>
            </div>
        `);
        newWin.document.close()
        setTimeout(() => {
            newWin.print();
        }, 1000);
    },
    cards3(){
        var g = this;
        var newWin= window.open("");
        newWin.document.write(`
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet">
        <style>
        *{
            font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
        }
        table, td, th {
            border: 1px solid #999;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl;
            height: 100%
        }
        .card{
            border: 2px dashed #ddd !important;
            height: 28mm;
        }
        .card .f{
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .card .f2{
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: none;
            height: 100%
        }
        .f .text{
            position: relative;
            top: 0px
        }
        span{
            font-size: 18px
        }
        @media print {
            @page {
                size: 100mm 28mm;
                margin: 0;
            }
        }
        </style>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css">
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" rel="stylesheet" />
        `)
        var cards = "";
        g.users.forEach(function(a){
            cards = cards + 
            `<div class='card' style='width: 100%; height: 28mm; background: #eee'>
                <div class='f'>
                    <div class='text row'>
                        <div class='col-9 text-center'>
                            <img style='width: 100%;' src='/images/sb.jpeg'>
                            <br>
                            ${a.name}
                        </div>
                        <div class='col-3 text-center' style='padding-top: 5px'>
                            <img style='width: 100%;' src='https://quickchart.io/chart?cht=qr&chld=H%7C1&chs=400x400&chl=${encodeURI(a.number)}'>
                            <br>
                            <small style='font-size: 8px'>${g?.busses_obj[a?.bus_id]?.title ?? "--"} <i class='fa fa-bus'></i></small>
                            <br>
                            <small style='font-size: 8px'>920009333 <i class='fa fa-phone'></i></small>
                        </div>
                    </div>
                </div>
            </div>`
        })
        newWin.document.write(`
            <div class='container-fluid'>
                <div class='row'>
                    ${cards}
                </div>
            </div>
        `);
        newWin.document.close()
        setTimeout(() => {
            newWin.print();
        }, 1000);
    },
    excel(){
      var g =this;
        g.printing = true
        setTimeout(() => {
            var table_id = "table"
            var separator = ','
            // Select rows from table_id
            var rows = document.querySelectorAll('table#' + table_id + ' tr');
            // Construct csv
            var csv = [];
            for (var i = 0; i < rows.length; i++) {
                var row = [], cols = rows[i].querySelectorAll('td, th');
                for (var j = 0; j < cols.length; j++) {
                    // Clean innertext to remove multiple spaces and jumpline (break csv)
                    var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                    data = data.replace(/"/g, '""');
                    // Push escaped string
                    row.push('"' + data + '"');
                }
                csv.push(row.join(separator));
            }
            var csv_string = csv.join('\n');
            // Download it
            var filename = 'bashaer_' + new Date().toLocaleDateString() + "_" + Math.random().toString().split(".")[1].substring(0,3) + '.csv';
            var link = document.createElement('a');
            link.style.display = 'none';
            link.setAttribute('target', '_blank');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => {
                g.printing = false;
            }, 500);
        }, 100);
    }
  }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>
